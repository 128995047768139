
import { reactive, ref } from "vue"
import RenderInstructionService from "../../../../services/RenderInstructionService"

export default {
  setup() {
    const service = new RenderInstructionService("luts")
    const additionalFields = reactive({ lutFile: "", alias: "" })
    const columns = reactive([
      { field: "lutFile", header: "LutFile", width: "5rem" },
      { field: "alias", header: "Alias", width: "5rem" },
    ])
    const collection = ref()

    function closeDialogs() {
      collection.value.addDialog = false
      collection.value.editDialog = false
      collection.value.duplicateDialog = false
      collection.value.reload()
    }

    return {
      service,
      additionalFields,
      columns,
      collection,
      closeDialogs,
    }
  },
}
